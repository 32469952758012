import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import cc from 'classcat';
import { useRouter } from 'next/router';
import { Text, Anchor } from '../../../Text';
import { Login } from '../Login';
import { Icon } from '../../../Icon';
import styles from './header.module.sass';
import HeaderCruises from './HeaderCruises/HeaderCruises';
import HeaderShips from './HeaderShips/HeaderShips';
import { useAuthStore } from '../../../../providers/RootStoreProvider';
import { useSearchStore } from '../../../../providers/SearchStoreProvider';
import { Logo } from '../../../Logo';
import { YaAndGaHeader } from '../../../../lib/YaAndGaHelper';
import { IconSVG } from '../../../SpriteSVG';
import { SkeletonCover } from '../../../Skeleton/SkeletonCover';
import { isClient } from '../../../../utils/isClient';
import { useCheckStep1Unregistered } from '../../../../hooks/useCheckStep1Unregistered';

export const Header = observer(
  ({
    isMobile,
    shipsSea,
    isOutsPage,
    isStep,
    sea,
    topContent,
    showTopContent,
    popularPopdirs,
    urlBF,
  }) => {
    const router = useRouter();
    const isUnregistered = useCheckStep1Unregistered();
    const store = useAuthStore();
    const { mainResult } = useSearchStore();
    const firstLoading = mainResult.isFirstLoading;
    const { type } = router.query;
    const isSea =
      sea ||
      type === 'sea' ||
      router.asPath.startsWith('/sea_cruise') ||
      router.asPath.startsWith('/sea');
    const currentPage = router.pathname;
    const activePage = router.pathname;

    useEffect(() => {
      mainResult.setLoading();
    }, []);
    return (
      <header
        className={cc([
          [styles.wrapper],
          {
            [styles.wrapperBlackFriday]: urlBF === 'black_friday',
          },
        ])}
      >
        {!isStep && <>{isClient && showTopContent && topContent}</>}
        <div
          className={cc([
            [styles.headerNav],
            {
              [styles.headerNavBf]: urlBF === 'black_friday',
            },
          ])}
          id="np"
        >
          <nav className="container">
            <div className={styles.header}>
              {!store.isB2bMode && (
                <div className={styles.logo}>
                  <Logo h={54} w={168} urlBF={urlBF} />
                </div>
              )}
              {!isStep && (
                <div className={styles.headerTop}>
                  <div
                    className={cc([
                      [styles.contactsBar],
                      {
                        [styles.contactsBarBf]: urlBF === 'black_friday',
                      },
                    ])}
                  >
                    {!isSea && !shipsSea && (
                      <div className={styles.timeTable}>
                        <>
                          <div className={styles.InTimeTable}>
                            <Icon
                              rightGap={8}
                              icon={
                                <IconSVG
                                  name={'common/timetable'}
                                  width={20}
                                  height={20}
                                />
                              }
                              title="Расписание отправлений"
                            />

                            <a
                              className={styles.timeTableNone}
                              href="/departures"
                              rel="nofollow noreferrer"
                              onClick={() => {
                                YaAndGaHeader('click_departure_schedule');
                              }}
                            >
                              <Text
                                variant={
                                  urlBF === 'black_friday'
                                    ? 'timeTableBf'
                                    : 'timeTable'
                                }
                              >
                                РАСПИСАНИЕ ОТПРАВЛЕНИЙ
                              </Text>
                            </a>
                            {urlBF === 'black_friday' ? (
                              <Icon
                                leftGap={8}
                                icon={
                                  <IconSVG
                                    name={'common/orandeArrow'}
                                    width={11}
                                    height={10}
                                  />
                                }
                                height={11}
                                width={11}
                                title="Стрелочка"
                              />
                            ) : (
                              <Icon
                                leftGap={8}
                                icon={
                                  <IconSVG
                                    name={'common/arrow'}
                                    width={11}
                                    height={10}
                                  />
                                }
                                height={11}
                                width={11}
                                title="Стрелочка"
                              />
                            )}
                          </div>
                        </>
                      </div>
                    )}
                    <div className="container container--end container--between">
                      {!store.isB2bMode && !isStep && (
                        <div className={styles.iconsWrapper}>
                          <div className="header-phone">
                            <a
                              href="tel:84958775834"
                              onClick={() => {
                                YaAndGaHeader('click_phone_number');
                              }}
                              style={
                                urlBF === 'black_friday'
                                  ? { color: '#fff' }
                                  : { color: 'none' }
                              }
                            >
                              +7 495 877-58-34
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <ul
                    itemScope
                    itemType={'https://schema.org/SiteNavigationElement'}
                    className={styles.menu}
                  >
                    <li
                      className={cc([
                        [styles.menuItem],
                        {
                          [styles.menuItemBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      <Text
                        weight="700"
                        decoration="none"
                        onClick={() => {
                          YaAndGaHeader('hover_on_part_cruises');
                        }}
                        variant={
                          (currentPage.includes('cruise') && 'activePage') ||
                          (currentPage.includes('/result') && 'activePage') ||
                          (currentPage.includes('/twentyThirdYear') &&
                            'activePage') ||
                          (currentPage.includes('/popdir') && 'activePage')
                        }
                      >
                        Круизы
                      </Text>
                      {/* TODO: вынести в отдельный компонет, положить рядом с хедером */}
                      <HeaderCruises
                        isSea={isSea}
                        shipsSea={shipsSea}
                        popularPopdirs={popularPopdirs}
                        isMobile={isMobile}
                        urlBF={urlBF}
                      />
                    </li>
                    <li
                      className={cc([
                        [styles.menuItem],
                        {
                          [styles.menuItemBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      <Text
                        weight="700"
                        decoration="none"
                        onClick={() => {
                          YaAndGaHeader('hover_on_part_ships');
                        }}
                        variant={
                          (currentPage.includes('liners') && 'activePage') ||
                          (currentPage.includes('ships') && 'activePage')
                        }
                      >
                        {isSea || shipsSea ? 'Лайнеры' : 'Теплоходы'}
                      </Text>
                      <HeaderShips
                        isSea={isSea}
                        shipsSea={shipsSea}
                        isMobile={isMobile}
                        urlBF={urlBF}
                      />
                    </li>
                    <li className={styles.menuItem} itemProp="name">
                      {!isSea && !shipsSea && (
                        <Anchor
                          weight="700"
                          href="/discount"
                          decoration="none"
                          onClick={() => {
                            YaAndGaHeader('click_sale_page');
                          }}
                          style={
                            urlBF === 'black_friday'
                              ? { color: '#fff' }
                              : { color: 'none' }
                          }
                          itemProp="url"
                          variant={
                            currentPage.includes('discount') && 'activePage'
                          }
                        >
                          Скидки и акции
                        </Anchor>
                      )}
                    </li>
                    <li className={styles.menuItem} itemProp="name">
                      <Anchor
                        weight="700"
                        href="/blog"
                        decoration="none"
                        itemProp="url"
                        style={
                          urlBF === 'black_friday'
                            ? { color: '#fff' }
                            : { color: 'none' }
                        }
                        variant={currentPage.includes('blog') && 'activePage'}
                      >
                        Блог
                      </Anchor>
                    </li>
                    {!store.isB2bMode && (
                      <li className={styles.menuItem} itemProp="name">
                        <Anchor
                          weight="700"
                          href="/about"
                          decoration="none"
                          onClick={() => {
                            YaAndGaHeader('click_about_company');
                          }}
                          itemProp="url"
                          variant={
                            currentPage.includes('about') && 'activePage'
                          }
                          style={
                            urlBF === 'black_friday'
                              ? { color: '#fff' }
                              : { color: 'none' }
                          }
                        >
                          О компании
                        </Anchor>
                      </li>
                    )}
                    <li
                      className={
                        activePage === '/contact'
                          ? styles.menuItemActive
                          : styles.menuItem
                      }
                      itemProp="name"
                    >
                      <Anchor
                        weight="700"
                        href="/contact"
                        decoration="none"
                        onClick={() => {
                          YaAndGaHeader('click_contacts_company');
                        }}
                        itemProp="url"
                        variant={
                          currentPage.includes('contact') && 'activePage'
                        }
                        style={
                          urlBF === 'black_friday'
                            ? { color: '#fff' }
                            : { color: 'none' }
                        }
                      >
                        Контакты
                      </Anchor>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {!firstLoading ? (
              !isUnregistered && <div className={styles.actions}>
                <Login
                  isMobile={isMobile}
                  isOutsPage={isOutsPage}
                  isStep={isStep}
                  urlBF={urlBF}
                />
              </div>
            ) : (
              <div className={styles.actions}>
                {' '}
                <SkeletonCover width={185} height={37} />
              </div>
            )}
          </nav>
        </div>
      </header>
    );
  },
);
