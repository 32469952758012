import { makeAutoObservable, runInAction } from 'mobx';
import d from 'dayjs';
import Router from 'next/router';
import { toast } from 'react-toastify';

export class MyCabinetStore {
  root;
  api;
  token;
  nextOrder;
  pastOrder;
  detailOrder;
  userInfo;
  touristsInfo;
  cabinsInfo;
  managerInfo;
  startDayCruise;
  prepayDays;
  orderDocs;
  payLinks;
  payByDate;
  promoPercent;
  promoName;
  promoPrice;
  orderPassengersInfo;
  loadingDocs = true;
  rosneft_id = 0;
  promoCode;
  qrCode = null;
  paymentId = null;
  paymentCheckTimer;
  orderStatus;
  dataMobileSbp;
  isLoadingQR;
  stepMobileSbp;
  lastFiveIds;
  loadingLinks;
  daDataToken = 'f8d266da9c82d7e33a1e51cb3f5542fe24c7303b';
  ownerInfo;
  clickToPay;
  checkPayLinkInterval;
  countCheckPayLink = 0;
  activeToken = '';

  constructor(root, api) {
    this.root = root;
    this.api = api;
    this.token = '';
    this.data = [];
    this.sbpToggledModal = false;
    this.sberPayToggledModal = false;
    this.paymentCheckTimer = null;
    this.stepMobileSbp = 'initial';

    makeAutoObservable(this);
  }

  prepDate(date) {
    if (date === '0000-00-00') {
      return '';
    }
    if (date === '1899-11-30') {
      return '';
    }
    if (date === '') {
      return '';
    }
    if (date === null) {
      return '';
    }
    if (date?.includes('-')) {
      return date?.split('-')?.reverse()?.join('.');
    }
    if (date?.includes('.')) {
      return date?.split('.')?.reverse()?.join('.');
    }
    return '';
  }

  prepareUserInfo(data) {
    return {
      ...data,
      prepBirthday: this.prepDate(data.birthday),
      passDate: this.prepDate(data.passp_date),
      passNumber: data.passp_number,
      passOrgan: data.passp_vidan,
      residentialAddress: data.residential_address,
      residenceAddress: data.residence_address,
      citizenship: data.citizenship,
      gender: data.sex ?? 1,
      passp_type: data.pasport_type ?? '',
      post_code: data.post_code ?? '',
      phone: data.phone ?? '',
      email: data.email ?? '',
    };
  }

  prepareTouristsInfo(data) {
    return data?.map(i => ({
      ...i,
      prepBirthday: this.prepDate(i.birthday),
      passDate: this.prepDate(i.pasport_date),
      passNumber: i.pasport_n,
      passOrgan: i.pasport_organ,
      residentialAddress: i.residential_address,
      residenceAddress: i.residence_address,
      gender: i.sex,
    }));
  }

  prepareOwnerInfo(data) {
    return {
      ...data,
      prepBirthday: this.prepDate(data.birthday),
      passDate: this.prepDate(data.pasport_date),
      passNumber: data.pasport_n,
      passOrgan: data.pasport_organ,
      passp_type: data.pasport_type,
      gender: data.sex,
    };
  }

  prepareListTrip(data) {
    return data?.map(i => ({
      ...i,
      prepDateStart: d(i.kr_date_start).format('DD.MM.YYYY'),
      prepDateEnd: d(i.kr_date_end).format('DD.MM.YYYY'),
      prepDateOrder: d(i.order_date).format('D MMMM YYYY'),
      betweenCount: i.route_full.split(' – ').slice(1, -1),
      firstCity: i.route_full.split(' – ').shift(),
      lastCity: i.route_full.split(' – ').pop(),
    }));
  }

  prepareDetailsOrder(data) {
    return {
      ...data,
      prepDateStart: d(data?.kr_date_start).format('DD.MM.YYYY'),
      prepDateEnd: d(data?.kr_date_end).format('DD.MM.YYYY'),
      prepDateOrder: d(data?.order_date).format('D MMMM YYYY'),
      betweenCount: data?.route_full?.split(' – ').slice(1, -1),
      firstCity: data?.route_full?.split(' – ').shift(),
      lastCity: data?.route_full?.split(' – ').pop(),
    };
  }
  fullPayForDays(data) {
    const year = Number(d(data?.kr_date_start).format('YYYY'));
    const month = Number(d(data?.kr_date_start).format('M'));
    const day = Number(d(data?.kr_date_start).format('D'));
    const date = new Date(year, month - 1, day);
    return d(date.setDate(date.getDate() - data?.prepay_days)).format(
      'DD.MM.YYYY',
    );
  }
  async getOtherTourists() {
    const { api } = this;
    await api
      .getUserPassengers(this.activeToken ? this.activeToken : this.token)
      .then(res => {
        runInAction(() => {
          this.touristsInfo = this.prepareTouristsInfo(res?.data);
        });
      });
  }

  async getOwnerParamsByToken() {
    const { api } = this;
    try {
      await api
        .getOwnerParamsByToken(this.activeToken ? this.activeToken : this.token)
        .then(res => {
          runInAction(() => {
            this.ownerInfo = this.prepareOwnerInfo(res?.data);
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  async deleteOtherTourists(id) {
    const { api } = this;
    await api
      .deleteUserPassenger(this.token, id)
      .then(() => this.getOtherTourists());
  }

  checkCompletePassenger(values) {
    let count = 0;
    // eslint-disable-next-line array-callback-return
    values.map(value => {
      const prepared = [
        value.surname,
        value.name,
        value.passNumber?.toString(),
        value.passDate ?? '',
        value.passOrgan,
        value.pasport_type?.toString() ?? '',
        value.gender?.toString(),
        value.birthday,
        value.patronymic,
      ];
      if (!prepared.includes('')) {
        count += 1;
      }
    });
    return count;
  }

  checkCompletedInfo(value) {
    const prepared = [
      value.name ?? '',
      value.surname ?? '',
      value.passNumber?.toString() ?? '',
      value.passDate ?? '',
      value.passOrgan ?? '',
      value.pasport_type?.toString() ?? '',
      value.gender?.toString() ?? '',
      value.birthday ?? '',
      value.patronymic ?? '',
    ];
    return prepared.includes('');
  }
  checkCompletedOwner(value) {
    const prepared = [
      value.name ?? '',
      value.surname ?? '',
      value.passNumber?.toString() ?? '',
      value.passDate ?? '',
      value.passOrgan ?? '',
      value.pasport_type?.toString() ?? '',
      value.gender?.toString() ?? '',
      value.birthday ?? '',
      value.patronymic ?? '',
      value.post_code?.toString() ?? '',
      value.residental_address?.toString() ?? '',
    ];
    return prepared.includes('');
  }

  async editOtherTourists(values, id) {
    const { api } = this;
    await api
      .editUserPassengers(
        this.activeToken ? this.activeToken : this.token,
        id,
        {
          name: values.name,
          surname: values.surname,
          patronymic: values.patronymic,
          sex: values.gender,
          birthday: values.birthday.split('.').reverse().join('.'),
          pasport_n: values.passNumber,
          pasport_organ: values.passOrgan,
          residential_address: values.residenceAddress,
          pasport_date: values.passDate.split('.').reverse().join('.'),
          pasport_type: values.passp_type,
          post_code: values.post_code,
          phone: values.phone,
          email: values.email,
          isOwnerOptions: values.isOwnerOptions ?? false,
        },
      )
      .then(() => this.getOtherTourists());
  }

  async callBackPayment(payLinks) {
    const { api } = this;
    await api.callBackPayment(payLinks);
  }

  async addOtherTourist(values) {
    const { api } = this;

    await api
      .createPassenger(this.token, {
        name: values.name,
        surname: values.surname,
        patronymic: values.patronymic,
        sex: values.gender,
        birthday: values.birthday.split('.').reverse().join('.'),
        pasport_n: values.passNumber,
        pasport_organ: values.passOrgan,
        residential_address: values.residentialAddress,
        pasport_date: values.passDate.split('.').reverse().join('.'),
      })
      .then(() => this.getOtherTourists());
  }

  async showUserInfo() {
    const { api } = this;

    await api.getUserInfo(this.token).then(res => {
      runInAction(() => {
        this.userInfo = this.prepareUserInfo(res.data);
      });
    });
  }

  async editUserInfo(values, id) {
    const { api } = this;
    await api
      .editUserInfo(this.token, id, {
        name: values.name,
        surname: values.surname,
        patronymic: values.patronymic,
        sex: values.gender,
        birthday: values.birthday.split('.').reverse().join('.'),
        phone: values.phone,
        email: values.email,
        passp_number: values.passNumber,
        passp_vidan: values.passOrgan,
        passp_date: values.passDate.split('.').reverse().join('.'),
        residenceAddress: values.residenceAddress,
        pasport_type: values.passp_type,
        citizenship: values.citizenship,
        isOwnerOptions: values.isOwnerOptions ?? false,
      })
      .then(() => this.showUserInfo());
  }

  async updateOwnerParamsByToken(values, errors, passenger) {
    const { api } = this;
    try {
      await api.updateOwnerParamsByToken(
        this.activeToken ? this.activeToken : this.token,
        {
          name: !errors?.includes('name') ? values.name : passenger?.name,
          surname: !errors?.includes('surname')
            ? values?.surname
            : passenger?.surname,
          patronymic: !errors?.includes('patronymic')
            ? values?.patronymic
            : passenger?.patronymic,
          sex: !errors?.includes('gender') ? values?.gender : passenger?.sex,
          birthday: !errors?.includes('birthday')
            ? values?.birthday?.split('.').reverse().join('.')
            : passenger?.birthday,
          pasport_n: !errors?.includes('passNumber')
            ? values?.passNumber
            : passenger?.pasport_n,
          pasport_organ: !errors?.includes('passOrgan')
            ? values?.passOrgan
            : passenger?.pasport_organ,
          pasport_date: !errors?.includes('passDate')
            ? values?.passDate?.split('.').reverse().join('.')
            : passenger?.pasport_date,
          residental_address: values?.residenceAddress ?? '',
          pasport_type: values?.passp_type,
          post_code: !errors?.includes('post_code')
            ? values?.post_code
            : passenger?.post_code,
          phone: !errors?.includes('phone') ? values?.phone : passenger?.phone,
          email: values?.email,
          citizenship: values.citizenship,
        },
      );
    } catch (e) {
      console.log(e);
    }
  }

  async sendYmClientId(userId, clientId) {
    const { api } = this;
    await api.sendYmClientId(userId, clientId);
  }
  async unsetPassengerAsOwner(id, switchOn, useFetch) {
    const { api } = this;
    try {
      if (switchOn) {
        await api.unsetPassengerAsOwner(
          this.activeToken ? this.activeToken : this.token,
          id,
        );
        await useFetch();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async sendHint(value, setValue, noIndex) {
    const { api } = this;
    try {
      await api.getDaDataHint(value, setValue, this.daDataToken, noIndex);
    } catch (e) {
      console.log(e);
      e.message.includes('403') &&
        window.ym(
          35441960,
          'reachGoal',
          'address_residence_autocomplete_limit_exceeded',
        );
    }
  }

  async getMyBooking() {
    const { api } = this;

    await api.getUserOrders(this.token).then(res => {
      const { next_orders, past_orders, order_status, lastFiveIds } = res.data;
      runInAction(() => {
        this.nextOrder = this.prepareListTrip(next_orders);
        this.pastOrder = this.prepareListTrip(past_orders);
        this.orderStatus = order_status;
        this.lastFiveIds = lastFiveIds;
      });
    });
  }

  async getRosneftId(orderId, number) {
    const { api } = this;
    await api.getRosneftCard(number, orderId.orderId);
  }

  getRosneftCardStatus(orderId) {
    const { api } = this;
    if (this.token) {
      api
        .getDetailOrder(this.token, orderId.orderId)
        .then(res => {
          this.rosneft_id = res?.data.rosneft_id;
        })
        .catch(e => console.log(e, 'eeee'));
    }
    return this.rosneft_id;
  }

  async getMyOrderDetail(orderId) {
    const { api } = this;
    if (this.token) {
      await api.getDetailOrder(this.token, orderId).then(res => {
        runInAction(() => {
          this.detailOrder = this.prepareDetailsOrder(res?.data);
          this.payByDate = this.fullPayForDays(res?.data);
          this.startDayCruise = res?.data.kr_date_start;
          this.prepayDays = res?.data.prepay_days;
          this.managerInfo = res?.data.order_manager;
          this.root.promoCode.setPromoPercent(res?.data.promo_percent);
          this.root.promoCode.setPromoName(res?.data.promo_name);
          this.root.promoCode.setPromoPrice(res?.data.promo_sum);
        });
      });
    }
  }

  async getOrderPassengers(orderId) {
    const { api } = this;
    if (this.token) {
      await api
        .getOrderPassengers(
          this.activeToken ? this.activeToken : this.token,
          orderId,
        )
        .then(res => {
          runInAction(() => {
            this.orderPassengersInfo = this.prepareTouristsInfo(
              Object.values(res?.data),
            );
          });
        });
    }
  }

  async getOrderDocs(orderId) {
    const { api } = this;
    await api.getOrderDocuments(this.token, orderId).then(res => {
      this.orderDocs = res?.data;
      try {
        if (res?.data?.documents?.length < 2) {
          this.loadingDocs = true;
          api.genOrderDocuments(orderId).then(() => {
            this.getOrderDocs(orderId);
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        this.loadingDocs = false;
      } finally {
        this.loadingDocs = false;
      }
    });
  }

  async getOrderPayLinks(orderId, date, prepayDays) {
    const { api } = this;
    this.loadingLinks = true;

    try {
      this.loadingLinks = true;
      await api.getOrderPayLinks(orderId, date, prepayDays).then(res => {
        this.payLinks = res.data;
        this.loadingLinks = false;
      });
    } catch (error) {
      console.log(error);
      this.loadingLinks = false;
    }
  }

  async editUserOrder(values, userId, errors, passenger, setOwner) {
    const { api } = this;
    await api.editUserOrder(
      this.activeToken ? this.activeToken : this.token,
      userId,
      {
        name: !errors?.includes('name') ? values?.name : passenger?.name,
        surname: !errors?.includes('surname')
          ? values?.surname
          : passenger?.surname,
        patronymic: !errors?.includes('patronymic')
          ? values?.patronymic
          : passenger?.patronymic,
        sex: !errors?.includes('gender') ? values?.gender : passenger?.sex,
        birthday: !errors?.includes('birthday')
          ? setOwner
            ? values?.birthday
            : values?.birthday?.split('.').reverse().join('.')
          : passenger?.birthday,
        pasport_n: !errors?.includes('passNumber')
          ? values?.passNumber
          : passenger?.pasport_n,
        pasport_organ: !errors?.includes('passOrgan')
          ? values?.passOrgan
          : passenger?.pasport_organ,
        pasport_date: !errors?.includes('passDate')
          ? values?.passDate?.split('.').reverse().join('.')
          : passenger?.pasport_date,
        residential_address: !errors?.includes('residenceAddress')
          ? values?.residenceAddress
          : passenger?.residental_address,
        pasport_type: values?.passp_type,
        post_code: !errors?.includes('post_code')
          ? values?.post_code
          : passenger?.post_code,
        phone: !errors?.includes('phone') ? values?.phone : passenger?.phone,
        email: values?.email,
        citizenship: values?.citizenship,
        isOwnerOptions: setOwner ? true : values?.isOwnerOptions,
      },
    );
  }

  async getCabinsInfo(orderId) {
    const { api } = this;

    await api.getDetailOrder(this.token, orderId).then(res => {
      if (res?.data?.order_cabins) {
        this.cabinsInfo = Object.entries(res?.data?.order_cabins).map(
          ([cab, inf]) => ({
            cabins: cab,
            info: Object.values(inf),
          }),
        );
      }
      return [];
    });
  }

  setSbpStatus(status) {
    this.sbpStatus = status;
  }
  setOrderId(orderId) {
    this.orderId = orderId;
  }
  setSBPToggledModal(toggled) {
    this.sbpToggledModal = toggled;
  }

  setSberPayToggledModal(toggled) {
    this.sberPayToggledModal = toggled;
  }

  setPaymentOptions(values) {
    this.paymentOptions = values;
  }

  getQR = async (orderId, amount, dataType, pay_percent) => {
    this.isLoadingQR = true;
    try {
      const { api } = this;
      const { data } = await api.getQR(orderId, amount, dataType, pay_percent);
      this.isLoadingQR = false;
      this.paymentLink = data.payload;
      this.qrCode = data.qr_code;
      this.idTinkoff = data.id_tinkoff;
    } catch (e) {
      this.isLoadingQR = false;
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  setDataMobileSbp(device, price, percent) {
    this.dataMobileSbp = {
      id: this.orderId,
      device,
      price,
      percent,
    };
  }

  setStepMobileSbp(status) {
    this.stepMobileSbp = status;
  }

  async checkStatusSBP() {
    const { api } = this;
    this.paymentCheckTimer = setInterval(() => {
      api
        .sbpChecked(this.idTinkoff)
        .then(response => {
          const { status } = response.data;
          if (status === 'CONFIRMED') {
            this.setSBPToggledModal(false);
            this.stopPaymentCheck();
            this.setSbpStatus(status);
            Router.push(
              {
                pathname: `/thanksStep`,
                query: {
                  type: this.paymentOptions,
                  sbp: 1,
                  order: this.orderId,
                },
              },
              undefined,
              { scroll: true },
            );
          }
        })
        .catch(error => {
          console.error(error);
        });
    }, 2000);
  }

  async getOrderPayLinkStatus(orderId, date, prepayDays) {
    const { api } = this;
    try {
      this.loadingLinks = true;
      if (this.countCheckPayLink < 3) {
        await api.getOrderPayLinks(orderId, date, prepayDays).then(res => {
          this.payLinks = res.data;
        });
        this.countCheckPayLink += 1;
      }
      if (!this.payLinks?.fullpay.link && this.countCheckPayLink !== 3) {
        await this.getOrderPayLinkStatus(orderId, date, prepayDays);
      }
      if (this.payLinks?.fullpay.link) {
        this.countCheckPayLink = 0;
        this.loadingLinks = false;
      }
      if (this.countCheckPayLink === 3) {
        toast.error('Произошла ошибка, повторите попытку позже');
        this.loadingLinks = false;
        this.countCheckPayLink = 0;
      }
    } catch (error) {
      console.log(error);
      this.loadingLinks = false;
    }
  }

  stopPaymentCheck() {
    if (this.paymentCheckTimer) {
      clearInterval(this.paymentCheckTimer);
      this.paymentCheckTimer = null;
    }
  }

  stopCheckPayLink() {
    if (this.checkPayLinkInterval) {
      clearInterval(this.checkPayLinkInterval);
      this.checkPayLinkInterval = null;
    }
  }

  async sendFormToEmail(form, link, utm) {
    const { api } = this;
    await api.sendForm({ ...form, link, utm }).then(res => {
      if (res.status === 200) {
        window.ym(35441960,'reachGoal','step2_order')
        Router.push(
          {
            pathname: `/thanksStep`,
            query: {
              type: 'isDisabledOnlinePay',
              order: this.orderId,
              unregistered: true,
            },
          },
          undefined,
          { scroll: true },
        );
      }
    });
  }

  hydrate(data = {}) {
    this.token = data?.token ?? '';
  }
}
