import { logger } from './logger';
import { isJSON } from '../utils/isJSON';

export const interceptorSwitch = res => {
  const safeStringify = (data) => {
    try {
      return JSON.stringify(data);
    } catch (error) {
      return '';
    }
  };

  const isSuccessResponse = (response) => response.status === 200 && 
           !isJSON(JSON.stringify(response.data)) && 
           typeof response.data !== 'string';


  // eslint-disable-next-line default-case
  switch (true) {
    // Новые эндпоинты из GSSP
    case res.request.path.includes('checkIsBuyCruise'):
      return logger({
        additionalFields: {
          type: 'all',
          endpoint: 'User/checkIsBuyCruise',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          id_cruise: res.request.path.split('/')[5],
          user_id: res.request.path.split('/')[6],
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'client buy status received' : 'failed to get client buy status'
      });

    case res.request.path.includes('ship_cruises'):
      return logger({
        additionalFields: {
          type: 'river',
          endpoint: 'ApiShips/ship_cruises',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          ship_url: res.request.path.split('/')[5],
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'ship cruises list received' : 'failed to get ship cruises list'
      });

    case res.request.path.includes('getHeaderPopdir'):
      return logger({
        additionalFields: {
          type: 'all',
          endpoint: 'ApiPopdirNew/getHeaderPopdir',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'header popdir data received' : 'failed to get header popdir data'
      });

    // Существующие эндпоинты
    case res.request.path.includes('apiPopular'):
      return logger({
        additionalFields: {
          type: 'river',
          endpoint: '/ApiPopdir/apiPopular',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'api popular cruise river received' : 'failed to get popular river cruises'
      });

    case res.request.path.includes('getPopdirSlider'):
      return logger({
        additionalFields: {
          type: 'all',
          endpoint: '/ApiPopdir/getPopdirSlider',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'popdir slider data received' : 'failed to load popdir slider'
      });

    case res.request.path.includes('get_popdir'):
      return logger({
        additionalFields: {
          type: 'all',
          endpoint: '/ApiPopdir/get_popdir',
          typeEnv: 'ssr',
          popdir_id: `${res.data.href}`,
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'seo text popdir data received' : 'failed to load seo text popdir'
      });

    case res.request.path.includes('check_token'):
      return logger({
        additionalFields: {
          type: 'all',
          endpoint: '/ApiUsers/check_token/token',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'critical',
        message: isSuccessResponse(res) ? 'user information received' : 'Failed to get user information'
      });

    case res.request.path.includes('cruise_data'):
      return logger({
        additionalFields: {
          type: 'sea',
          endpoint: 'ApiCruise/cruise_data',
          typeEnv: 'ssr',
          id_sea_cruise: `${res.data.cruise?.cruise_id}`,
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'cruise sea data received' : 'failed to cruise sea data'
      });

    case res.request.path.includes('LoadPlaces2'):
      return logger({
        additionalFields: {
          type: 'river',
          endpoint: 'ApiCruise/cruise_data',
          typeEnv: 'ssr',
          path: `${res.request.path}`,
          id_cruise: `${res.request.path.split('/')[5]}`,
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: 'cruise_data'
      });

    case res.request.path.includes('sendVendorsPage'):
      return logger({
        additionalFields: {
          type: 'all',
          endpoint: 'ApiVendors/sendVendorsPage',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'vendors data received' : 'failed to get vendors data'
      });

    case res.request.path.includes('get_cruis_list/new_river'):
      return logger({
        additionalFields: {
          type: 'river',
          endpoint: 'Booking2019/get_cruis_list/new_river',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'river cruises data received' : 'failed to get river cruises data'
      });

    case res.request.path.includes('getHomepageBanners'):
      return logger({
        additionalFields: {
          type: res.request.path.includes('is_sea=1') ? 'sea' : 'river',
          endpoint: 'ApiBanners/getHomepageBanners',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'sea banners data received' : 'failed to get data sea banners'
      });

    case res.request.path.includes('apiPopularSea'):
      return logger({
        additionalFields: {
          type: 'sea',
          endpoint: 'ApiPopdir/apiPopularSea',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'api popular cruise sea received' : 'failed to get popular sea cruises'
      });

    case res.request.path.includes('main_reviews'):
      return logger({
        additionalFields: {
          type: 'all',
          endpoint: '/ApiReviews/main_reviews',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'main reviews data received' : 'Failed to get main review data'
      });

    case res.request.path.includes('reviews'):
      return logger({
        additionalFields: {
          type: 'river',
          endpoint: 'ApiShips/reviews',
          typeEnv: 'ssr',
          id_review_ship: `${res.request.path.split('/')[5]}`,
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'review ship data received' : 'failed to get data review ship'
      });

    case res.request.path.includes('get_cruis_list/sea'):
      return logger({
        additionalFields: {
          type: 'sea',
          endpoint: 'Booking2019/get_cruis_list/sea',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'cruises sea data received' : 'failed to load sea cruises data'
      });

    case res.request.path.includes('getPopdirPage'):
      return logger({
        additionalFields: {
          type: 'river',
          endpoint: '/ApiPopdir/getPopdirPage',
          typeEnv: 'ssr',
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'popdir cities data received' : 'failed to load popdir cities'
      });

    case res.request.path.includes('newShipsScheme'):
      return logger({
        additionalFields: {
          type: 'all',
          endpoint: 'ApiShips/newShipsScheme',
          typeEnv: 'ssr',
          ship_id: `${res.data.ship_id}`,
          request_id: `${res.config.headers.request_id}`,
          is_Not_JSON: !isJSON(JSON.stringify(res.data)),
          is_string_response: typeof res.data === 'string',
        },
        level: isSuccessResponse(res) ? 'debug' : 'error',
        message: isSuccessResponse(res) ? 'shema received' : 'failed to load shema'
      });

      case res.request.path.includes('getLikeCruises'):
            return logger({
              additionalFields: {
                type: 'river',
                endpoint: 'ApiCruise/getLikeCruises',
                typeEnv: 'ssr',
                id_like_cruise: `${res.request.path.split('/')[5]}`,
                request_id: `${res.config.headers.request_id}`,
                is_Not_JSON: !isJSON(JSON.stringify(res.data)),
                is_string_response: typeof res.data === 'string',
              },
              level: isSuccessResponse(res) ? 'debug' : 'error',
              message: isSuccessResponse(res) ? 'similar cruises data received' : 'failed to get data similar cruises'
            });
        }
      };
      