import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

export const useCheckStep1Unregistered = () => {
  const router = useRouter();
  const [isStep1Unregistered, setIsStep1Unregistered] = useState(false);

  useEffect(() => {
    if (router.isReady) {
      const currentPath = router.asPath; // Получаем текущий путь с query строкой

      // Проверяем, содержит ли URL `step_1_unregistered`
      if (currentPath.includes('step_1_unregistered')) {
        setIsStep1Unregistered(true);
      } else {
        setIsStep1Unregistered(false);
      }
    }
  }, [router.isReady, router.asPath]);

  return isStep1Unregistered;
};
